import React from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import WorkSlideshow from "./workSlideshow";

const WorkPage = (props) => {
  const title = props.data.allFile.edges[0].node.name;
  return (
    <Layout location={props.location} title={title}>
      <WorkSlideshow work={props} />
    </Layout>
  );
};

export default WorkPage;

export const pageQuery = graphql`
  query PostQuery($name: String!) {
    allFile(
      filter: { sourceInstanceName: { eq: "work" }, name: { eq: $name } }
    ) {
      edges {
        node {
          name
          sourceInstanceName
          childMarkdownRemark {
            frontmatter {
              work_items {
                date
                dimensions
                image {
                  publicURL
                  childImageSharp {
                    original {
                      src
                    }
                    fluid(maxHeight: 600) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                material
                work_title
              }
            }
          }
        }
      }
    }
  }
`;
